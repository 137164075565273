<template>
  <v-card tile elevation="0">
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="4">
            <v-img
              src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg"
            ></v-img>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="profile.email" disabled></v-text-field>
            <v-text-field
              v-model="profile.username"
              :label="$t('page.profile.username.label')"
              disabled
            ></v-text-field>
            <!-- <v-textarea :label="$t('page.profile.bio.label')"></v-textarea> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import * as types from "../../store/mutation-types";
export default {
  components: {},
  data() {
    return {
      profile: {},
    };
  },
  computed: {},
  created() {
    this.profile = this.$store.getters["profile/profile"];
  },
};
</script>